import React from 'react';

import * as rad from 'react-admin';
import { helpers as auth } from './authProvider';
import { ListActions, EditToolBar, CustomPagination } from './sharedComponents'



const updateForm = isNewResource =>
    <rad.SimpleForm toolbar={<EditToolBar />} redirect="list">
        {(!isNewResource && <rad.TextField source="id" />)}
        <rad.NumberInput source="sort" />
        <rad.TextInput source="url" />
        <rad.ImageInput source="icon" label="Icon" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.ImageInput source="iconNegative" label="Icon negative" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.ReferenceInput source="stop" reference="stops">
            <rad.SelectInput optionText="name"></rad.SelectInput>
        </rad.ReferenceInput>
        <rad.BooleanInput source="allowed" />
        <rad.NumberInput source="viewed" min={0} />
        <rad.TextInput source="name" />

    </rad.SimpleForm>;


export const ItemsList = props => {
    
    let Actions = ListActions();

    return <rad.List {...props} bulkActionButtons={auth.isAdmin() ? props.bulkActionButtons : false} actions={<Actions />} pagination={<CustomPagination />} >
        <rad.Datagrid>
            <rad.TextField source="id" />
            <rad.NumberField source="sort" />
            <rad.UrlField source="url" />
            <rad.ImageField source="icon.src" label="Icon" />
            <rad.ImageField source="iconNegative.src" label="Icon negative" />
            <rad.ReferenceField reference="stops" source="stop">
                <rad.ChipField source="name" />
            </rad.ReferenceField>
            <rad.BooleanField source="allowed" />
            <rad.NumberField source="viewed" />
            <rad.TextField source="name" />
            <rad.ShowButton />
            <rad.EditButton />
        </rad.Datagrid>
    </rad.List>
}



export const ItemsShow = props => {
    return <rad.Show {...props}>
        <rad.SimpleShowLayout>
            <rad.TextField source="id" />
            <rad.NumberField source="sort" />
            <rad.UrlField source="url" />
            <rad.ImageField source="icon.src" label="Icon" />
            <rad.ImageField source="iconNegative.src" label="Icon negative" />
            <rad.ReferenceField reference="stops" source="stop">
                <rad.ChipField source="name" />
            </rad.ReferenceField>
            <rad.BooleanField source="allowed" />
            <rad.NumberField source="viewed" />
            <rad.TextField source="name" />
        </rad.SimpleShowLayout>
    </rad.Show>

}

export const ItemsEdit = props => (<rad.Edit {...props}>{updateForm(false)}</rad.Edit>);

export const ItemsCreate = props => (<rad.Create {...props}>{updateForm(true)}</rad.Create>);