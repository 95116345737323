import React from 'react';

import * as rad from 'react-admin';
import { helpers as auth } from './authProvider';
import { ListActions, EditToolBar, CustomPagination } from './sharedComponents'


const updateForm = isNewResource =>
    <rad.SimpleForm toolbar={<EditToolBar />} redirect="list">
        {(!isNewResource && <rad.TextField source="id" />)}
        <rad.ImageInput source="image" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>

        <rad.ImageInput source="carouselImage1" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.TextInput source="carouselUrl1" />

        <rad.ImageInput source="carouselImage2" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.TextInput source="carouselUrl2" />

        <rad.ImageInput source="carouselImage3" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.TextInput source="carouselUrl3" />

        <rad.ImageInput source="carouselImage4" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.TextInput source="carouselUrl4" />

        <rad.ImageInput source="carouselImage5" labelSingle="Drop a picture to upload, or click to select it." accept="image/*">
            <rad.ImageField source="src"></rad.ImageField>
        </rad.ImageInput>
        <rad.TextInput source="carouselUrl5" />

        <rad.TextInput source="name" />

        <rad.ReferenceArrayInput source="users" reference="users">
            <rad.SelectArrayInput optionText="name"></rad.SelectArrayInput>
        </rad.ReferenceArrayInput>
    </rad.SimpleForm>

export const StopList = props => {

    let Actions = ListActions();
    

    return <rad.List {...props} bulkActionButtons={auth.isAdmin() ? props.bulkActionButtons : false} actions={<Actions />} pagination={<CustomPagination />} >
        <rad.Datagrid>
            <rad.TextField source="id" />

            <rad.ReferenceArrayField
                fieldKey="id"
                reference="items"
                source="items">
                <rad.SingleFieldList linkType="edit">
                    <rad.ChipField source="name" />
                </rad.SingleFieldList>
            </rad.ReferenceArrayField>

            <rad.ImageField source="image.src" label="Image"></rad.ImageField>

            <rad.TextField source="name"></rad.TextField>

            <rad.ReferenceArrayField
                reference="users"
                source="users">
                <rad.SingleFieldList linkType={auth.isAdmin() ? "edit" : false}>
                    <rad.ChipField source="name" />
                </rad.SingleFieldList>
            </rad.ReferenceArrayField>

            <rad.ShowButton />
            <rad.EditButton />
        </rad.Datagrid>
    </rad.List>
}


export const StopShow = props => {

    return <rad.Show {...props}>
        <rad.SimpleShowLayout>
            <rad.TextField source="id" />

            <rad.ReferenceArrayField
                reference="items"
                source="items">
                <rad.SingleFieldList linkType="edit">
                    <rad.ChipField source="name" />
                </rad.SingleFieldList>
            </rad.ReferenceArrayField>

            <rad.ImageField source="image.src" label="Image"></rad.ImageField>

            <rad.TextField source="name" />

            <rad.ReferenceArrayField
                reference="users"
                source="users">
                <rad.SingleFieldList linkType={auth.isAdmin() ? "edit" : false}>
                    <rad.ChipField source="name" />
                </rad.SingleFieldList>
            </rad.ReferenceArrayField>

        </rad.SimpleShowLayout>
    </rad.Show>

}

export const StopEdit = props => (<rad.Edit {...props}>{updateForm(false)}</rad.Edit>);

export const StopCreate = props => (<rad.Create {...props}>{updateForm(true)}</rad.Create>);