import * as React from 'react';
import * as rad from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from './repsol-logo.png';
import { Box } from '@material-ui/core';
import { helpers as auth } from './authProvider';


const useStyles = makeStyles({
    appbar: {
        "background-color": '#000B45',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    logo: {
        maxWidth: "90px",
    },
});

const MyUserMenu = (props) => {
    let userName = auth.userName();
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <rad.UserMenu {...props}></rad.UserMenu>
            <span>{userName ? userName[0].toUpperCase() + userName.slice(1): ""}</span>
        </Box>
    );
};


const MyAppBar = props => {
    const classes = useStyles();
    return (
        <rad.AppBar {...props} className={classes.appbar} userMenu={<MyUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <img alt="" src={logo} className={classes.logo}></img>
            <span className={classes.spacer} />
        </rad.AppBar>
    );
};


export default (props) => <rad.Layout {...props} appBar={MyAppBar} />;