import React from 'react';
import * as rad from 'react-admin';
import { HydraAdmin } from '@api-platform/admin';
import authProvider from './authProvider';
import { UserList, UserEdit, UserShow, UserCreate } from './user';
import { StopList, StopEdit, StopShow, StopCreate } from './stops';
import { ItemsList, ItemsEdit, ItemsShow, ItemsCreate } from './items';
import myLayout from './layout'
import theme  from "./theme";
import UserIcon from '@material-ui/icons/People'
import PlaceIcon from '@material-ui/icons/Place';
import CategoryIcon from '@material-ui/icons/Category';
import dataProvider from './dataProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';


const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });

export default () => {
    return (
        <HydraAdmin layout={myLayout} theme={theme} authProvider={authProvider} entrypoint={entrypoint} dataProvider={dataProvider(entrypoint)}
	   i18nProvider={i18nProvider}>
            <rad.Resource name={"users"} list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} icon={UserIcon}/>
            <rad.Resource name={"stops"} list={StopList} edit={StopEdit} show={StopShow} create={StopCreate} icon={PlaceIcon}/>
            <rad.Resource name={"items"} list={ItemsList} edit={ItemsEdit} show={ItemsShow} create={ItemsCreate} icon={CategoryIcon}/>
        </HydraAdmin>)
}
