import React from 'react';
import * as rad from 'react-admin';
import { helpers as auth } from './authProvider';
import { ListActions, EditToolBar, CustomPagination } from './sharedComponents'



const updateForm = isNewResource =>
    <rad.SimpleForm toolbar={<EditToolBar />} redirect="list">
        {(!isNewResource && <rad.TextField source="id" />)}
        <rad.TextInput source="email" />
        <rad.TextInput source="plainPassword" type="password" />
        <rad.TextInput source="name" />
    </rad.SimpleForm>;


export const UserList = props => {
    let Actions = ListActions({hasCreate: auth.isAdmin()});

    return <rad.List {...props} bulkActionButtons={auth.isAdmin() ? props.bulkActionButtons : false} actions={<Actions/>} pagination={<CustomPagination />} >
        <rad.Datagrid>
            <rad.TextField source="id" />
            <rad.EmailField source="email" />
            <rad.TextField source="name" />
            <rad.ShowButton />
            <rad.EditButton />
        </rad.Datagrid>
    </rad.List>
}


export const UserShow = props => {
    return <rad.Show {...props}>
        <rad.SimpleShowLayout>
            <rad.TextField source="id" />
            <rad.EmailField source="email" />
            <rad.TextField source="name" />
        </rad.SimpleShowLayout>
    </rad.Show>
}

export const UserEdit = props => (<rad.Edit {...props}>{updateForm(false)}</rad.Edit>);

export const UserCreate = props => (<rad.Create {...props}>{updateForm(true)}</rad.Create>);

