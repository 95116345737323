import React from 'react';
import * as rad from 'react-admin';
import { helpers as auth } from './authProvider';


export const ListActions = ({ hasCreate = true, hasExport = true } = {}) => ({ basePath }) => (
    <rad.TopToolbar>
        {hasCreate && <rad.CreateButton basePath={basePath} />}
        {hasExport && <rad.ExportButton />}
    </rad.TopToolbar>

);

export const EditToolBar = props => (
    <rad.Toolbar {...props} >
        <rad.SaveButton />
        <span style={{ width: "100%" }}></span>
        {auth.isAdmin() && <rad.DeleteButton />}

    </rad.Toolbar>
);


export const CustomPagination = props => {

    const PaginationActions = props => {
        return <rad.PaginationActions {...props} color="secondary" onChangePage={props.onPageChange} />;
    }

    return <rad.Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} ActionsComponent={PaginationActions} />;
}