import decodeJwt from 'jwt-decode';



function decodedToken() {
    let token = localStorage.getItem('token');
    return token ? decodeJwt(token) : {};
}

const helpers = {
    isAdmin: () => decodedToken().roles[0] === "ROLE_ADMIN",
    userName: () => decodedToken().username,
}


// Change this to be your own authentication token URI.
const authenticationTokenUri = "https://api.backstopsrepsol.cidownstream.com/authentication_token";

export { helpers };


export default {
    login: ({ username, password }) => {
        const request = new Request(authenticationTokenUri, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem('token', token);
                window.location.replace('/');
            })
        },
    checkError: (error) => {
        const status = error.status;
        if (401 === status || 403 === status) {
            localStorage.removeItem('token');

            return Promise.reject();
        }
    },
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    getPermissions: () => {
        return Promise.resolve()
    }

}
