import React from 'react';
import * as rad from 'react-admin';
import { fetchHydra as baseFetchHydra, hydraDataProvider as baseHydraDataProvider, useIntrospection } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { Route, Redirect } from 'react-router-dom';


const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};


const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

async function fetchImage(entrypoint, id) {
    let response = await fetch(`${entrypoint}${id}`, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => data.contentUrl);

    return await response;
}

const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });

        api.resources.forEach(resource => {

            resource.fields.map(field => {
                if ('http://schema.org/accessCode' === field.id) {
                    field.input = (props) => (
                        <rad.TextInput {...props} type="password" autoComplete="off" />
                    );
                }

                if ('http://schema.org/image' === field.id) {
                    field.denormalizeData = (value) => {
                        if (typeof value === 'string') {
                            return fetchImage(entrypoint, value).then(data => {
                                return {
                                    src: data
                                }
                            });
                        }
                    };

                    field.field = props => (
                        <rad.ImageField {...props} source={`${field.name}.src`} />
                    );

                    field.input = () => (
                        <rad.ImageInput accept="image/*" key={field.name} multiple={false} source={field.name}>
                            <rad.ImageField source="src" />
                        </rad.ImageInput>
                    );

                    field.normalizeData = value => {
                        if (value && value.rawFile instanceof File) {
                            const body = new FormData();
                            body.append('file', value.rawFile);

                            return fetch(`${entrypoint}/images`, { body, method: 'POST', headers: getHeaders() })
                                .then((response) => response.json())
                                .then((responseData) => responseData['@id']);
                        }

                        if (value === null) {
                            return null;
                        }
                    };
                }

                return field;
            });

            return resource;
        });


        return { api };
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin} />
                ],
            };
        }

        throw result;
    }
}



export default (entrypoint) => baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);
